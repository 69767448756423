import React, { useState, useEffect } from 'react';
import { Container, Alert } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import PageAlert from './PageAlert';
import ImageMaker from './ImageMaker';
import SiteInfo from './SiteInfo';
import ProductCards from './ProductCards';
import CartModal from './CartModal';
import SuccessPage from './SuccessPage';  // Import the new SuccessPage component
import CancelPage from './CancelPage';  // Import the new CancelPage component
import ReactGA from 'react-ga4';
import { trackEvent, trackPageview } from './trackGA';
import CustomerDesigns from './CustomerDesigns';
import CustomerPage from './CustomerPage';
import ImageEditor from './ImageEditor';
import EmailPopup from './EmailPopup';

const TRACKING_ID = "G-97H3QJ4DCQ";

function CartIcon({ itemCount, handleCartClick }) {
  console.log("item_count", itemCount);
  return (
    <>
      <style type="text/css">
        {`
    .cart-icon {
      position: fixed;
      top: 20px;
      right: 20px;
      font-size: 24px;
      cursor: pointer;
      z-index: 1000; /* Ensure the cart appears on top of all other components */
    }

    .cart-badge {
      position: absolute;
      top: 0;
      right: 0;
      background-color: red;
      color: white;
      border-radius: 50%;
      padding: 2px 6px;
      font-size: 12px;
    }
    `}
      </style>

      <div
        className="cart-icon d-flex"
        id="cart-icon"
        style={{ cursor: "pointer" }}
        onClick={handleCartClick}
      >
        {/* SVG for a realistic shopping cart icon */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={30}
          height={30}
          fill="currentColor"
          className="bi bi-cart me-2"
          viewBox="0 0 16 16"
        >
          <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
        </svg>
        <span
          className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
          id="cart-count"
        >
          {itemCount || 0}
        </span>
      </div>
    </>
  );
}

const Main = () => {
  const [cartItems, setCartItems] = useState([]);  // Manage shopping cart items
  const [showCartModal, setShowCartModal] = useState(false);  // Manage modal visibility
  const [alertMessage, setAlertMessage] = useState('');  // Manage alert message
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const gaDisabled = searchParams.get('ga_disabled') === 'true';

    if (gaDisabled) {
      console.log("Google Analytics is disabled via URL parameter.");
    } else {
      console.log("Initializing Google Analytics");
      ReactGA.initialize(TRACKING_ID);
      trackPageview("/", "Landing Page");
    }

    const src = searchParams.get('src');
    trackEvent('Acquisition', src, 'Landing Page');
  }, [location]);

  // Handle adding items to the cart (grouped by productId, size, and color)
  const addToCart = (product, size, color, caption) => {
    const existingItemIndex = cartItems.findIndex(
      (item) => item.product.productId === product.productId &&
        item.size === size &&
        item.color === color &&
        item.caption === caption
    );

    trackEvent('Product', 'Add to Cart', product.productId);

    if (existingItemIndex >= 0) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += 1;
      setCartItems(updatedCartItems);
    } else {
      setCartItems([...cartItems, { product, size, color, caption, quantity: 1 }]);
    }
  };

  // Handle showing the cart modal
  const handleCartClick = () => {
    setShowCartModal(true);
  };

  // Handle closing the cart modal
  const handleClose = () => setShowCartModal(false);

  // Handle updating the quantity of an item in the cart
  const updateCartItemQuantity = (key, newQuantity) => {
    const updatedCartItems = cartItems.map((item) => {
      const itemKey = `${item.product.productId}-${item.size}-${item.color}-${item.caption}`;
      if (itemKey === key) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setCartItems(updatedCartItems);
  };

  // Handle removing an item from the cart
  const removeCartItem = (key) => {
    const updatedCartItems = cartItems.filter((item) => {
      const itemKey = `${item.product.productId}-${item.size}-${item.color}-${item.caption}`;
      return itemKey !== key;
    });
    setCartItems(updatedCartItems);
  };

  // Count the total number of items in the cart
  const totalCartItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <>
      <CartIcon itemCount={totalCartItems} handleCartClick={handleCartClick} />
      <PageAlert msg={alertMessage} setAlertMessage={setAlertMessage} />

      <Container className="px-4 pt-5 mb-5 text-center">

        <div className="d-none d-xl-block">
          <img
            src="/static/brush-logo.svg"
            alt="Brush Logo"
            className="position-fixed top-0 start-0 m-3"
            style={{ width: '80px', height: 'auto', zIndex: 1000 }}
          />
        </div>

        <Container className="col-md-8">
          <h1 className="display-4 fw-bold text-body-emphasis">
            Design and Print T-Shirts <br />For Anyone/Any Occasion by <span style={{ background: 'linear-gradient(to right, red, orange, green, blue, indigo, violet)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>AI</span>
          </h1>
          <p className="lead fw-bold">
            Customize your t-shirt with AI. <br />
            Ready to order in seconds. <br />
          </p>
        </Container>

        <ImageMaker addToCart={addToCart} setAlertMessage={setAlertMessage} />

        <Container className="col-md-11">
          <h2 className="mt-5 mb-3 display-5 fw-bold" id="customer-designs">Designed by Customers <span style={{ color: 'red' }}>❤️</span></h2>
          <CustomerDesigns />

          <h2 className="mt-5 mb-3 display-5 fw-bold" id="predesigned-t-shirts">Pre-Designed by AI</h2>
          <ProductCards addToCart={addToCart} setAlertMessage={setAlertMessage} />

          <SiteInfo />
        </Container>
      </Container>

      {/* CartModal component */}
      <CartModal
        cartItems={cartItems}
        show={showCartModal}
        handleClose={handleClose}
        updateCartItemQuantity={updateCartItemQuantity}
        removeCartItem={removeCartItem}
      />
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/cancel" element={<CancelPage />} />
        <Route path="/customer" element={<CustomerPage />} />
        <Route path="/image-editor" element={<ImageEditor />} />
      </Routes>
    </Router>
  );
};

export default App;