import React from 'react';
import { Carousel } from 'react-bootstrap';

const CustomerDesigns = () => {
    const designs = [
        { id: 1, image: 'static/customer-designs/eagle.png' },
        { id: 2, image: 'static/customer-designs/forever-strong.png' },
        { id: 3, image: 'static/customer-designs/go-lions.png' },
        { id: 4, image: 'static/customer-designs/grill.png' },
        { id: 5, image: 'static/customer-designs/guitar.png' },
        { id: 6, image: 'static/customer-designs/k.png' },
        { id: 7, image: 'static/customer-designs/rock-girl.png' },
    ];

    return (
        <div className="col-lg-6 col-12 mx-auto shadow">
            <Carousel interval={1000}  data-bs-theme="dark">
                {designs.map((design) => (
                    <Carousel.Item key={design.id}>
                        <img
                            className="d-block w-100"
                            src={design.image}
                            alt={design.caption}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default CustomerDesigns;
