import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const SiteInfo = () => {
  useEffect(() => {
    // Function to scroll to the element with the given ID
    const scrollToElement = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Check if there's a hash in the URL and scroll to the element
    if (window.location.hash) {
      const id = window.location.hash.substring(1); // Remove the '#'
      scrollToElement(id);
    }

    // Add event listener for hash changes
    const handleHashChange = () => {
      if (window.location.hash) {
        const id = window.location.hash.substring(1);
        scrollToElement(id);
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <div className="pt-5 mb-5 text-start">
      {/* FAQ Section */}
      <div className="p-3 p-md-5 mb-4 bg-body-tertiary rounded-3">
        <div id="faq" className="container-fluid py-3 py-md-5">
          <h2 className="display-5 fw-bold mb-4">FAQ</h2>
          <div className="mt-4">
            <h3 className="h5 fw-bold mb-3">How do I get rid of the background color of the design?</h3>
            <p className="mb-4">
              If the design has a light background color that can be easily removed, it will be removed when we print the T-Shirt. Otherwise, it will stay.
            </p>
            <h3 className="h5 fw-bold mb-3">Is the design free?</h3>
            <p className="mb-4">
              Yes, no sign up or login needed. Just click the "rainbow" button to generate a new design until you find one you like.
            </p>
            <h3 className="h5 fw-bold mb-3">How do I explore multiple designs?</h3>
            <p className="mb-4">
              You can click "Add to Cart" button to add the design to your cart, so you can re-visit them later to pick one.
            </p>
            <h3 className="h5 fw-bold mb-3" id="idea-examples">I don't see the style, recipient, or occasion I want. Can I still get a t-shirt with that design?</h3>
            <p className="mb-3">
              Yes, you can enter your own style, recipient, occasion and any details to generate a design using the text input box.
              The following are examples may give you some ideas:
            </p>
            <ul className="list-unstyled mb-4">
              <li className="mb-2">"Make a <strong>cool</strong> T-shirt for <strong>my friend's birthday with a cat design</strong>".</li>
              <li className="mb-2">"Make a <strong>happy and cartoon</strong> T-shirt for <strong>my friend's promotion</strong>".</li>
              <li className="mb-2">"Make a <strong>cartoon</strong> T-shirt for <strong>my trip to Hawaii</strong>".</li>
              <li className="mb-2">"Make a <strong>funny and sarcastic</strong> T-shirt for <strong>my friend's bachelorette party</strong>."</li>
              <li className="mb-2">"Make a <strong>sporty</strong> T-shirt for <strong>my team's next marathon event</strong>".</li>
              <li className="mb-2">"Make a <strong>futuristic and tech-inspired</strong> T-shirt for <strong>my coding hackathon</strong>".</li>
              <li className="mb-2">"Make a <strong>retro 90s</strong> T-shirt for <strong>my family reunion</strong>."</li>
              <li className="mb-2">"Make a <strong>minimalist and sleek</strong> T-shirt for <strong>my office retreat</strong>."</li>
              <li className="mb-2">"Make a <strong>vintage</strong> T-shirt for <strong>my dad's retirement party</strong>."</li>
              <li className="mb-2">"Make a <strong>space-themed</strong> T-shirt for <strong>my kid's science fair</strong>. Details: Must include text "Science Fair""</li>
              <li className="mb-2">"Make a <strong>bold and vibrant</strong> T-shirt for <strong>my upcoming music festival</strong>."</li>
              <li className="mb-2">"Make a <strong>abstract art</strong> T-shirt for <strong>my friend's gallery opening</strong>."</li>
              <li>"Make a <strong>geeky</strong> T-shirt for <strong>my gaming convention trip</strong>".</li>
            </ul>
            <h3 className="h5 fw-bold mb-3">How long does it take to get the t-shirt?</h3>
            <p className="mb-4">
              See the <a href="#Shipping">Shipping</a> section below.
            </p>
            <h3 className="h5 fw-bold mb-3">How's the quality of the t-shirt?</h3>
            <p className="mb-3">
              We use a professional printing company to print the t-shirts. The quality is great. Here are the t-shirt models we use:
            </p>
            <ul className="list-unstyled mb-4">
              <li className="mb-2">Baby: Bella + Canvas 3001B (100% cotton)</li>
              <li className="mb-2">Toddler: Bella + Canvas 3001T (100% cotton)</li>
              <li className="mb-2">Youth: Bella + Canvas 3001Y (100% cotton)</li>
              <li>Adults: Unisex Staple T-Shirt | Bella + Canvas 3001 (100% cotton)</li>
            </ul>
          </div>
        </div>
      </div>

      {/* About Section */}
      <div className="p-3 p-md-5 mb-4 bg-body-tertiary rounded-3">
        <div id="About" className="container-fluid py-3 py-md-5">
          <h2 className="display-5 fw-bold mb-4">About</h2>
          <p className="fs-5">
            We are the parents of two boys, Charlie and Chase, who often have wild imaginations. We thought it would be cool for them to show off their creativity on their t-shirts. With the help of AI, we can now make it happen easily! We hope you enjoy this as much as we do. By the way, you don't have to be a kid to wear these t-shirts—we offer adult sizes too. :)
          </p>
        </div>
      </div>

      {/* Shipping, Return Policy, and Contact Sections */}
      <Row className="align-items-md-stretch">
        <Col md={6} className="mb-4">
          <div id="Shipping" className="h-100 p-3 p-md-5 bg-body-tertiary rounded-3">
            <h2 className="mb-3">Shipping</h2>
            <p>
              It takes some time to print your design and ship. Usually, it takes 4-10 days. We only ship within the US.
            </p>
          </div>
        </Col>
        <Col md={6} className="mb-4">
          <div id="Return" className="h-100 p-3 p-md-5 bg-body-tertiary rounded-3">
            <h2 className="mb-3">Return Policy</h2>
            <p>
              We hope you are 100% satisfied. If not, please contact support@aipainttee.com for returns and a full refund.
            </p>
          </div>
        </Col>
        <Col md={6} className="mb-4">
          <div id="Contact" className="h-100 p-3 p-md-5 bg-body-tertiary rounded-3">
            <h2 className="mb-3">Contact</h2>
            <p>
              Please contact us at <a href="mailto:support@aipainttee.com">support@aipainttee.com</a> for any questions or order changes.
            </p>
          </div>
        </Col>
      </Row>


      {/* Footer Section */}
      <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <p className="col-md-8 mb-0 text-body-secondary">
            &copy; 2024 AI Paint Tee, Madison, WI
          </p>
          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li className="ms-3"><a className="text-body-secondary" href="https://www.facebook.com/people/AI-Paint-Tee-Design-T-Shirts-For-AnyoneAny-Occasion-by-AI/61566118551550/" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook fs-5"></i></a></li>
            <li className="ms-3"><a className="text-body-secondary" href="https://x.com/AiPaintTee" target="_blank" rel="noopener noreferrer"><i className="bi bi-twitter-x fs-5"></i></a></li>
            <li className="ms-3"><a className="text-body-secondary" href="https://www.instagram.com/jingjun.sh/" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram fs-5"></i></a></li>
          </ul>
        </footer>
      </div>
    </div>
  );
};

export default SiteInfo;