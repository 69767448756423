import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, ProgressBar, ButtonGroup, Modal } from 'react-bootstrap';
import './ImageMaker.css';  // Import the custom CSS file
import { trackEvent } from './trackGA';

import ProductOptions from './ProductOptions';  // Import the ProductOptions component
import Designer from './Designer';  // Import the Designer component
import getCookie from './GetCookie';
import ImageEditor from './ImageEditor';  // Import the ImageEditor component
import EmailPopup from './EmailPopup';  // Import the EmailPopup component


const RequirementsDisplay = ({ customRequirements }) => {
    return (
        <span>with the following requirements: <strong>{customRequirements}</strong></span>
    );
}

const PromptDisplay = ({ designStyle, target, customRequirements }) => {
    return (
        <div className="alert alert-warning" role="alert">
            Make a <strong>{designStyle}</strong> t-shirt for <strong>{target}</strong> {customRequirements ? <RequirementsDisplay customRequirements={customRequirements} /> : ''}
        </div>
    );
}

const ImageMaker = ({ addToCart, setAlertMessage }) => {
    const [text, setText] = useState('');
    const [progress, setProgress] = useState(0); // State to control progress bar value
    const [generatedImage, setGeneratedImage] = useState(''); // State to store the generated image
    const [isGenerating, setIsGenerating] = useState(false); // State to control button disabling
    const [imageId, setImageId] = useState(null); // State to store the unique image ID
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [rating, setRating] = useState(0); // State to store the rating
    const [hover, setHover] = useState(0); // State to handle hover effect on stars
    const [feedbackComment, setFeedbackComment] = useState(''); // State to store feedback comment
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [remainingRequests, setRemainingRequests] = useState(null);
    const [caption, setCaption] = useState('');
    const [addCaption, setAddCaption] = useState(false); // Changed to false by default
    const [displayedCaption, setDisplayedCaption] = useState('');
    const [showEmailPopup, setShowEmailPopup] = useState(false);
    const [hasSeenEmailPopup, setHasSeenEmailPopup] = useState(false);

    const styles = ['Sarcastic', 'Minimalist', 'Logo', 'Cartoon', 'Humorous', 'Funny', 'Cute', 'Vintage', 'Humorous and Cartoon'];
    const targets = [
        "Lazy Husband",
        "Mean Wife",
        "Boyfriend who doesn't listen",
        "Girlfriend who loves to shop",
        "Dad who loves to grill",
        "Mom who loves to bake",
        "Brother",
        "Christmas Party",
    ];

    const [designStyle, setDesignStyle] = useState(styles[0]);
    const [target, setTarget] = useState(targets[0]);
    const [customStyle, setCustomStyle] = useState('');
    const [customTarget, setCustomTarget] = useState('');
    const [customRequirements, setCustomRequirements] = useState('');
    const [isCustomStyleActive, setIsCustomStyleActive] = useState(false);
    const [isCustomTargetActive, setIsCustomTargetActive] = useState(false);

    const price = '14.99'; // Regular variable for price

    useEffect(() => {
        fetchRemainingRequests();
    }, []);

    const simulateProgress = () => {
        let intervalId;
        intervalId = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(intervalId); // Stop when progress reaches 100%
                    return 100;
                }
                return prevProgress + 1; // Increment progress
            });
        }, 400); // Increment every 400ms for smooth progress
        return intervalId;
    };

    const handlePaintButtonClick = async () => {
        const hasSubmittedEmail = localStorage.getItem('hasSubmittedEmail') === 'true';

        if (!hasSubmittedEmail && !hasSeenEmailPopup) {
            setShowEmailPopup(true);
            setHasSeenEmailPopup(true);
        }

        if (!designStyle || !target) {
            return;
        }

        setProgress(0);
        setGeneratedImage('');
        setIsGenerating(true);
        setImageId(null);

        // Reset rating and feedback
        setRating(0);
        setHover(0);
        setFeedbackComment('');
        setFeedbackSubmitted(false);

        // Reset caption and displayed caption
        setCaption('');
        setDisplayedCaption('');
        setAddCaption(false);

        trackEvent('Product', 'Generate Image', `${designStyle} - ${target} - ${customRequirements}`);

        let interval = simulateProgress();

        try {
            const response = await fetch('/generate-image/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                body: JSON.stringify({ style: designStyle, target, custom_requirements: customRequirements }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                setAlertMessage(errorData.error_msg || 'Failed to generate image.');
                return;
            }

            const data = await response.json();
            const taskId = data.task_id;

            // Poll for task completion
            const result = await pollTaskCompletion(taskId);

            if (result.error_msg) {
                console.log('result.error_msg:', result.error_msg);
                console.log('result.internal_error:', result.internal_error);
                setAlertMessage(result.error_msg + ' ' + result.internal_error);
                return;
            }

            setGeneratedImage(`data:image/png;base64,${result.image_base64}`);
            setImageId(result.image_id);
        } catch (error) {
            console.error('Error generating image:', error);
            setAlertMessage('Failed to generate image. Please try again. Error: ' + error);
        } finally {
            clearInterval(interval);
            setIsGenerating(false);
            await fetchRemainingRequests();
        }
    };

    const pollTaskCompletion = async (taskId) => {
        const maxAttempts = 30; // 1 minutes (2 * 30 seconds)
        const interval = 2000; // 2 seconds

        for (let attempt = 0; attempt < maxAttempts; attempt++) {
            console.log(`Polling for task completion: ${attempt} of ${maxAttempts}`);
            const response = await fetch(`/check-task-status/${taskId}/`);

            if (!response.ok) {
                const errorData = await response.json();
                setAlertMessage(`Error: ${errorData.result.error_msg || 'An unknown error occurred'}`);
                throw new Error('Polling for task completion failed: ' + errorData.result.error_msg);
            }

            const data = await response.json();

            if (data.status === 'completed') {
                setCaption(data.result.caption || '');
                return data.result;
            }

            await new Promise(resolve => setTimeout(resolve, interval));
        }

        setAlertMessage('Task timed out');
        throw new Error('Task timed out');
    };

    const handleAddToCart = () => {
        trackEvent('Product', 'Add to Cart', imageId);
        if (imageId && selectedSize && selectedColor) {
            const customProduct = {
                productId: imageId,
                productImage: generatedImage,
                productTitle: "Custom AI-Generated T-Shirt",
                productDescription: text,
                productPrice: price
            };
            addToCart(customProduct, selectedSize, selectedColor, addCaption ? caption : "NO CAPTION");
        } else {
            if (!selectedSize) {
                setAlertMessage('Please select a size.');
            } else if (!selectedColor) {
                setAlertMessage('Please select a color.');
            } else if (!generatedImage) {
                setAlertMessage('Please select a design style and target, then click "AI Paint Tee" button.');
            }
        }
    };

    const handleFeedbackSubmit = async () => {
        if (rating === 0) {
            setAlertMessage('Please select a rating before submitting feedback.');
            return;
        }

        try {
            console.log('Submitting feedback:', {
                imageId,
                rating,
                feedbackComment
            });
            const response = await fetch('/submit-feedback/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                body: JSON.stringify({
                    image_id: imageId,
                    rating: rating,
                    comment: feedbackComment
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to submit feedback.');
            }

            setFeedbackSubmitted(true);
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setAlertMessage('Failed to submit feedback. Please try again.');
        }
    };

    const handleEditButtonClick = () => {
        setShowEditor(true);
    };

    const handleCloseEditor = () => {
        setShowEditor(false);
    };

    const handleImageEdited = (editedImageData) => {
        setGeneratedImage(editedImageData);
        setShowEditor(false);
    };

    const fetchRemainingRequests = async () => {
        try {
            const response = await fetch('/remaining-requests/');
            if (!response.ok) {
                throw new Error('Failed to fetch remaining requests');
            }
            const data = await response.json();
            setRemainingRequests(data.remaining_requests);
        } catch (error) {
            console.error('Error fetching remaining requests:', error);
        }
    };

    const handleCaptionChange = (e) => {
        setCaption(e.target.value);
        if (addCaption) {
            setDisplayedCaption(e.target.value);
        }
    };

    const handleAddCaptionChange = (e) => {
        setAddCaption(e.target.checked);
        if (e.target.checked) {
            setDisplayedCaption(caption);
        } else {
            setDisplayedCaption('');
        }
    };

    const handleContainerContextMenu = (e) => {
        // Only prevent right-click if it's on the generated image
        if (e.target.classList.contains('overlay-image')) {
            e.preventDefault();
            trackEvent('Product', 'Right-click-design', imageId);
        }
    };

    return (
        <>
            <Container className="col-lg-10 mt-4" onContextMenu={handleContainerContextMenu}>
                <Row>
                    <Col xl={6}>
                        <Designer
                            styles={styles}
                            targets={targets}
                            designStyle={designStyle}
                            setDesignStyle={setDesignStyle}
                            target={target}
                            setTarget={setTarget}
                            customStyle={customStyle}
                            setCustomStyle={setCustomStyle}
                            customTarget={customTarget}
                            setCustomTarget={setCustomTarget}
                            isCustomStyleActive={isCustomStyleActive}
                            setIsCustomStyleActive={setIsCustomStyleActive}
                            isCustomTargetActive={isCustomTargetActive}
                            setIsCustomTargetActive={setIsCustomTargetActive}
                            customRequirements={customRequirements}
                            setCustomRequirements={setCustomRequirements}
                        />
                    </Col>
                    <Col xl={6}>
                        <PromptDisplay
                            designStyle={designStyle}
                            target={target}
                            customRequirements={customRequirements}
                        />
                        <div>
                            <Button
                                className="rainbow-button border-0"
                                disabled={!designStyle || !target || isGenerating}
                                onClick={handlePaintButtonClick}
                            >
                                <strong>{isGenerating ? 'Painting...' : 'AI Paint Tee'}</strong>
                            </Button>
                            {remainingRequests !== null && remainingRequests < 10 && (
                                <Form.Text className="text-muted d-block mt-2">
                                    Remaining requests: {remainingRequests}
                                </Form.Text>
                            )}
                        </div>


                        {/* Progress Bar */}
                        {isGenerating && !generatedImage && (
                            <Row className="mt-3">
                                <Col>
                                    <ProgressBar
                                        striped
                                        animated
                                        now={progress}
                                        variant="info"
                                    />
                                    <div className='text-info border border-info p-2 rounded mt-2 text-start' style={{ backgroundColor: '#f0f8ff' }}>
                                        <small>
                                            While waiting, explore <a href="#customer-designs" className="fw-bold text-info">designs by customers</a> or our <a href="#predesigned-t-shirts" className="fw-bold text-info">pre-designed collection</a>?
                                        </small>
                                    </div>
                                    <div className='text-info border border-info p-2 rounded mt-2 text-start' style={{ backgroundColor: '#f0f8ff' }}>
                                        <small>
                                            Not satisfied with the design? Try again. AI may take a few times to get it right.
                                            Also, consider filling the "OPTIONAL DETAILS" field.
                                        </small>
                                    </div>
                                    <div className='text-info border border-info p-2 rounded mt-2 text-start' style={{ backgroundColor: '#f0f8ff' }}>
                                        <small>
                                            <a href="#idea-examples" className="fw-bold text-info">Idea Examples</a> <br />
                                        </small>
                                    </div>
                                </Col>
                            </Row>
                        )}


                        <Row className="mt-3 position-relative">
                            <Col>
                                {/* Background image */}
                                <div className="image-container position-relative">
                                    <img
                                        src="/static/t-shirt-background-2.png"
                                        alt="T-shirt Background"
                                        className="img-fluid rounded-3 shadow"
                                    />
                                    {generatedImage && (
                                        <>
                                            <img
                                                src={generatedImage}
                                                alt="Generated Design"
                                                className="overlay-image rounded"
                                                onContextMenu={(e) => e.preventDefault()}
                                                draggable="false"
                                                onDragStart={(e) => e.preventDefault()}
                                            />
                                            {displayedCaption && (
                                                <div className="placeholder-text-container">
                                                    <div className="placeholder-text">
                                                        {displayedCaption}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>

                                {generatedImage && (
                                    <div className="my-3 border rounded py-2 px-3" style={{ backgroundColor: '#f8f9fa' }}>
                                        <h4 className="mb-0 me-3">CAPTION</h4>
                                        <div className="d-flex align-items-center mb-2 mx-auto">
                                            <Form.Check
                                                type="checkbox"
                                                id="add-caption-checkbox"
                                                label="Add Caption"
                                                checked={addCaption}
                                                onChange={handleAddCaptionChange}
                                            />
                                        </div>
                                        <Form.Group className="mb-2">
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter caption for your t-shirt"
                                                value={caption}
                                                onChange={handleCaptionChange}
                                                disabled={!addCaption}
                                            />
                                        </Form.Group>
                                        <Form.Text className="text-muted">
                                            Our human designers will align the caption text for you before printing.
                                        </Form.Text>
                                    </div>
                                )}


                            </Col>
                        </Row>


                        {(
                            <>
                                <div className="mt-3">
                                    <ProductOptions
                                        selectedColor={selectedColor}
                                        setSelectedColor={setSelectedColor}
                                        selectedSize={selectedSize}
                                        setSelectedSize={setSelectedSize}
                                    />
                                </div>
                                <Row className="mt-3">
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Button
                                                variant="primary"
                                                className="w-100"
                                                onClick={handleAddToCart}
                                            >
                                                Add to Cart (${price})
                                            </Button>
                                            <Form.Text className="text-muted mt-2 text-center">
                                                Save to cart so you don't lose it.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {generatedImage && (
                            <div className="mt-3 feedback-section p-3 border rounded shadow-sm">
                                <h5 className="mb-3">How did we do?</h5>
                                <div className="d-flex flex-column align-items-center">
                                    <ButtonGroup aria-label="Star rating" className="mb-3">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <Button
                                                key={star}
                                                variant={star <= (hover || rating) ? "primary" : "outline-primary"}
                                                onClick={() => setRating(star)}
                                                onMouseEnter={() => setHover(star)}
                                                onMouseLeave={() => setHover(rating)}
                                                className="px-3 py-2"
                                            >
                                                {star <= (hover || rating) ? '★' : '☆'}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </div>
                                <Form.Group className="mb-3" controlId="feedbackComment">
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Tell us what you think about the design..."
                                        value={feedbackComment}
                                        onChange={(e) => setFeedbackComment(e.target.value)}
                                        className="mb-3"
                                    />
                                </Form.Group>
                                <div className="text-center">
                                    <Button
                                        variant="outline-primary"
                                        onClick={handleFeedbackSubmit}
                                        className="px-4 py-2"
                                    >
                                        Submit Feedback
                                    </Button>
                                    {feedbackSubmitted && <div className="ms-2 text-success">Thank you for your feedback!</div>}
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>

            </Container>

            <Modal show={showEditor} onHide={handleCloseEditor} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ImageEditor
                        initialImage={generatedImage}
                        onImageEdited={handleImageEdited}
                    />
                </Modal.Body>
            </Modal>

            <EmailPopup 
                show={showEmailPopup} 
                setShow={setShowEmailPopup}
            />
        </>
    );
};

export default ImageMaker;